.pckge {}

.hprice .btn {
  display: none;
}

.hprice.grey-bg {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQwNC4yNTggNDA0LjI1OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjI1OCA0MDQuMjU4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KICAgIDxwb2x5Z29uIHBvaW50cz0iMTM4LjMzMSwwIDExNC4zMzEsMTggMjUyLjQyNywyMDIuMTI5IDExNC4zMzEsMzg2LjI1OCAxMzguMzMxLDQwNC4yNTggMjg5LjkyNywyMDIuMTI5ICIgZmlsbD0iI2Q5ZDlkOSIvPgogICAgPGc+CiAgICA8L2c+CiAgICA8Zz4KICAgIDwvZz4KICAgIDxnPgogICAgPC9nPgogICAgPGc+CiAgICA8L2c+CiAgICA8Zz4KICAgIDwvZz4KICAgIDxnPgogICAgPC9nPgogICAgPGc+CiAgICA8L2c+CiAgICA8Zz4KICAgIDwvZz4KICAgIDxnPgogICAgPC9nPgogICAgPGc+CiAgICA8L2c+CiAgICA8Zz4KICAgIDwvZz4KICAgIDxnPgogICAgPC9nPgogICAgPGc+CiAgICA8L2c+CiAgICA8Zz4KICAgIDwvZz4KICAgIDxnPgogICAgPC9nPgogIDwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: right -10px center;
  background-size: 50px;
}

.warning-icon {
    margin-right: 5px;
    top: 3px;
    position: relative;
}

@media only screen and (min-width: 992px) {
  .hprice.grey-bg {
    background-image: none;
  }
  .hprice .flight {
    display: none;
  }
  .hprice .next {
    display: none;
  }
  .hprice .btn {
    display: block;
    margin: 0;
  }
}
