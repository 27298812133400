.btn {
    font-size: 1.25rem;
    background: #fff;
    border: 1px solid #333;
    color: #333;
    padding: .5rem 3rem;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 700;
    margin-bottom: .9375rem;
    margin-top: .9375rem;
    transition: background-color .2s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Crystal-Light', sans-serif;
}

.btn.noborder {
    border: none;
}

.btn.nomargin {
    margin-top: 0;
    margin-bottom: 0;
}

.btn::before, .btn::after {
    content: '';
    flex: 1 0 auto;
}

.btn:hover {
    background: #fafafa;
}

.btn.primary {
    background: #47a404;
    color: #fff;
    border: 0;
    text-decoration: none;
}

.btn.primary:hover {
    background: #3c8b03;
}

.btn.secondary {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.btn.secondary:hover {
    background: #fff;
    color: #000;
}

.btn.small {
    font-size: .875rem;
    padding: .3rem 1rem;
    /* height: 33px;
  margin: 0; */
}

.btn.link {
    border: none;
    display: initial !important;
    font-size: 1rem;
    /* text-decoration: underline; */
    color: rgb(8, 154, 208);
    background: transparent;
    cursor: pointer;
    outline: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
    text-decoration: underline;
    white-space: normal;
}

.btn.link:hover {
    color: rgb(0, 129, 183);
}

.btn.link:nth-child(2) {
    margin-left: 0.3rem;
}

.btn.icon-left>i {
    margin-left: -12px;
    margin-right: 0.3rem;
}

.btn.icon-right>i {
    margin-right: -12px;
    margin-left: 0.3rem;
}

.btn.fullwidth {
    width: 100%;
}

.btn[disabled] {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

@media screen and (max-width541px) {
    .btn.small {
        font-size: .7rem;
        padding: 0.3rem;
    }
}