.list-item {
  padding: 1rem .9375rem;
  -webkit-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;
  /* border-bottom: 1px solid rgb(238, 238, 238); */
}

.list.dense .list-item {
  padding: 0.5rem .4687rem;
}

.list-item.clickable {
  cursor: pointer;
  user-select: none;
}

.list-item img.thumb {
  cursor: pointer;
  user-select: none;
  width: 110px;
  height: 60px;
  object-fit: cover;
  margin: 0 1.875rem;
}

.list-item.disabled {
  cursor: default;
  user-select: none;
  color: rgba(0, 0, 0, 0.26) !important;
}

.list-item.disabled * {
  color: rgba(0, 0, 0, 0.26) !important;
}

.list-item:first-child {
  /* border-top: 1px solid rgb(238, 238, 238); */
}

.list-item:last-of-type {
  border-bottom: none;
}

.list-item:nth-child(odd) {
  background-color: rgb(245, 245, 245);
}

.list.no-odd .list-item:nth-child(odd), .no-odd .list-item:nth-child(odd) {
  background-color: transparent;
}

.list.border .list-item {
  border-bottom: 1px solid rgb(238, 238, 238);
}

.list.border .list-item:first-child {
  border-top: 1px solid rgb(238, 238, 238);
}

.list.border .list-item:last-child {
  border-bottom: none;
}

.list-item p, .list-item h3 {
  margin: 0;
  margin-top: 0.3rem;
  line-height: 100%;
}

.list-item h3 {
  font-size: 1rem;
}

.list-item.no-padding {
  padding: 0;
}

.list-item a {
  text-decoration: none;
  color: #333;
}