.usp-list-item {
  padding: .175rem .9375rem;
  -webkit-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;
  font-size: 1.075rem;
  break-inside: avoid-column;
}

@media screen and (max-width: 992px) {
  .usp-list-item:last-child:last-child {
    margin-bottom: .9375rem;
  }
}

.usp-list-item .material-icons {
  margin-right: 4px;
}