.content {
    flex: 1;
    max-width: calc(1140px + (.9375rem * 2));
    margin: 0 auto;
    padding-left: .9375rem;
    padding-right: .9375rem;
    box-sizing: border-box;
    width: 100%;
}

.feed-content {
    flex: 1;
    max-width: calc(1440px + (.9375rem * 2));
    margin: 0 auto;
    padding-left: .9375rem;
    padding-right: .9375rem;
    box-sizing: border-box;
    width: 100%;
}
