.list-item-box {
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: .4687rem .9375rem;
  width: 100%;
  box-sizing: border-box;
}

.list-item-box.no-padding {
  padding: 0;
}

@media screen and (min-width: 992px) {
  .list-item-box {
    border-bottom: none;
  }
}

.list-item-box:last-of-type {
  border-bottom: none;
}

.list-item-box.left {
  border-left: 1px solid rgb(238, 238, 238);
}

.list-item-box.right {
  border-left: 1px solid rgb(238, 238, 238);
}

.list-item-box .icon-button {
  padding-left: 0;
}