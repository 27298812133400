.card {
  border-radius: 3px;
  /* box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .1); */
  border: 1px solid #ebebeb;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin-top: .9375rem;
  margin-bottom: .9375rem;
}

@media screen and (max-width: 992px) {
  .card {
    margin-top: .4687rem;
    margin-bottom: .4687rem;
  }
}