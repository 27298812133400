.package-info-card {
    margin: 2.5rem 0 0 0;
    background-color: #fffeec;
}

.info {
    margin: 0.4687rem 0 0 0;
    font-size: 0.875rem;
    font-weight: 500;
    width: 60%;
}

.package-img {
    width: 65px;
    height: 65px;
    margin-right: 0.4687rem;
}

@media screen and (max-width: 992px) {
    .package-info-card {
        margin-left: calc(-0.9375rem);
        margin-right: calc(-0.9375rem);
        width: calc(100% + (0.9375rem * 2));
        border-left: none;
        border-right: none;
    }
    .info {
        width: 80%;
    }
}

@media screen and (max-width: 540px) {
    .info-title {
        font-size: 1.1rem;
    }
    .package-img {
        position: absolute;
        top: -16.25px;
        right: 0;
    }
    .info {
        font-size: 0.7rem;
        width: 90%;
    }
}
