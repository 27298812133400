.select-wrapper {
  width: 100%;
  margin-top: .9375rem;
}

.select-wrapper select {
  font-size: .875rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgb(247, 247, 247);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMTI4cHgiIGhlaWdodD0iMTI4cHgiPgogIDxnPgogICAgPHBhdGggZD0ibTEyMS4zLDM0LjZjLTEuNi0xLjYtNC4yLTEuNi01LjgsMGwtNTEsNTEuMS01MS4xLTUxLjFjLTEuNi0xLjYtNC4yLTEuNi01LjgsMC0xLjYsMS42LTEuNiw0LjIgMCw1LjhsNTMuOSw1My45YzAuOCwwLjggMS44LDEuMiAyLjksMS4yIDEsMCAyLjEtMC40IDIuOS0xLjJsNTMuOS01My45YzEuNy0xLjYgMS43LTQuMiAwLjEtNS44eiIgZmlsbD0iIzg1ODU4NSIvPgogIDwvZz4KPC9zdmc+Cg==) right 5px center no-repeat #fff;
  background-size: 11px;
  border: 1px solid #d8d8d8;
  padding: 2px 20px 2px 10px;
  height: 40px;
  width: 100%;
  outline: 0;
  border-radius: 3px;
  font-family: inherit;
  box-sizing: border-box;
}

.select-wrapper label {
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.2rem;
  display: block;
  font-weight: 700;
}

.select-wrapper select::-ms-expand {
  display: none;
}
