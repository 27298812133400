.flight-card h4 {
  font-size: 1.1rem;
  text-align: left;
}

.flight-card .btn {
  display: none;
}

@media only screen and (min-width: 992px) {
  .flight-card .next {
    display: none;
  }
  .flight-card .btn {
    display: block;
    margin: 0;
  }
}

@media screen and (max-width: 992px) {
  .flight-card {
    margin-left: calc(-.9375rem);
    margin-right: calc(-.9375rem);
    width: calc(100% + (.9375rem * 2));
    border-left: none;
    border-right: none;
    cursor: pointer;
    user-select: none;
  }
  .flight-card .subtitle {
    font-size: .9rem;
  }
  .flight-card * {
    user-select: none;
  }
}