.summary {
  width: 100%;
  /* max-width: 1140px;
  margin: 0 auto; */
  color: #fff;
  box-sizing: border-box;
  /* min-height: 60px; */
}

.summary.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

.summary .summary-content {
  margin: 0 auto;
  padding: .9375rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 1140px;
  /* display: flex;
  flex-direction: column; */
}

.summary h4 {
  font-size: 1.1rem;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1.1;
}

.summary p {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  line-height: 1.1;
}

@media screen and (max-width: 992px) {
  .summary .summary-content {
    padding: .4687rem .9375rem
  }
  /* .summary h4 {
    font-size: 1.1rem;
  } */
}