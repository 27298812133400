.hratings .info {
  width: 100%;
  margin: 0;
  padding-top: .9375rem;
}

.hratings .cdsLocName {
  display: none;
}

.hratings .cdsROW.narrow {
  padding: 0;
}

.hratings .TA_cdsratingsonlynarrow {
  margin-top: -15px;
}

@media screen and (max-width: 992px) {
  .hratings {
    margin-top: .9375rem;
  }
}