.hotel-images img {
  background-size: cover;
  width: 100%;
  /* min-height: 667px;
  max-height: 667px; */
  -o-object-fit: cover;
  object-fit: cover;
  height: 100% !important;
}

.skeleton-img {
  height: 500px;
  background: rgba(133, 133, 133, .12);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .hotel-images {
    min-height: 40vh;
    height: 40vh;
  }
  .hotel-images.skeleton-img {
    min-height: 40vh;
    height: 40vh;
  }
}