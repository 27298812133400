/* .info {
  width: 100%;
} */

.info p, .info h5 {
  margin: 0;
  white-space: nowrap;
  text-align: center;
}

.info.text h4 {
  font-size: 1.7rem;
  font-weight: 700;
  white-space: nowrap;
}

/* 
@media screen and (max-width: 361px) {
  .info.text p:first-of-type {
    font-size: 1.4rem;
  }
} */

.info p {
  font-size: 0.7rem;
  font-weight: 700;
  color: rgb(200, 200, 200);
  white-space: nowrap;
}

.info i {
  margin-bottom: 3px;
}

@media screen and (max-width: 992px) {
  .info.text.resp h5 {
    font-size: 0.6rem;
  }
  .info.text.resp h4 {
    font-size: 0.8rem;
  }
  .info.resp p {
    font-size: 0.5rem;
    white-space: nowrap;
  }
}