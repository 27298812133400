.dialog .dialog-content {
  overflow-y: auto;
  /* height: calc(100% - 28px); */
  /* height: 100%; */
  flex: 1 1 auto;
}

@media screen and (min-width: 992px) {
  .dialog .dialog-content {
    max-height: 80vh;
  }
}