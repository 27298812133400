.input {
  width: 100%;
  margin-top: .9375rem;
}

.input>input, .react-phone-number-input__input.phone-input {
  font-size: .875rem;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 20px 2px 10px;
  height: 40px;
  width: 100%;
  outline: 0;
  border-radius: 3px;
  font-family: inherit;
  box-sizing: border-box;
  background: rgb(247, 247, 247);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.react-phone-number-input__icon {
  border: none !important;
}

.input>input:disabled, .react-phone-number-input__input.phone-input:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.input label {
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.2rem;
  display: block;
  font-weight: 700;
  min-height: 19px;
}

.input>input[type="date"]::-webkit-calendar-picker-indicator, .input>input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

.input>input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}