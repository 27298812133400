.list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.list.row {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 540px) {
    .list.row {
        flex-direction: column;
    }
}