.cc-dialog {
  max-width: 600px;
}

@media screen and (max-width: 992px) {
  .cc-dialog {
    max-width: 100%;
  }
}

.cc-dialog label {
  font-weight: normal;
  font-size: .875rem;
}

.cc-dialog .grey-bg {
  background: #FFDC0D;
}

.age-label {
  margin-bottom: -.8375rem !important;
}