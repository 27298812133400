.main-header {
  height: 60px;
  background: #fff;
  box-sizing: border-box;
  padding: 0.9375rem;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .2);
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  display: flex;
}

.main-header .icon-button {
  margin-right: -18px;
  z-index: 1;
}

.main-header a {
  color: #333;
  font-size: .875rem;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .main-header .icon-button {
    display: none;
  }
  .main-header a {
    font-size: 1rem;
  }
}