.sidenav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sidenav-backdrop {
  z-index: 1001;
  background-color: rgba(0, 0, 0, .85);
}

@keyframes slidein {
  from {
    left: -300px
  }
  to {
    left: 0;
  }
}

.sidenav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  animation: slidein 300ms ease;
  background-color: #fff;
  max-width: 320px;
}

/* @media (min-width: 992px) {
  .sidenav {
   
  }
}

@media screen and (max-width: 992px) {
  .sidenav-backdrop {
    display: none;
  }
} */

.sidenav .sidenav-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 28px)
}