.image-carousel {
  height: 100%;
}

.slick-dots li button:before {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 7px !important;
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

.slick-next {
  right: 10px;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li.slick-active button:before {
  color: #FFEC00 !important;
  font-size: 10px !important;
}

.slick-slider, .slick-slide>div, .slick-list, .slick-track {
  height: 100%;
}

.image-carousel .carousel-prev-arrow {
  height: 55px !important;
  width: 65px;
  left: 0;
  opacity: 0.6;
}

.image-carousel .carousel-next-arrow {
  opacity: 0.6;
  right: 0;
  height: 55px !important;
  width: 65px;
}

.image-carousel .carousel-small-arrow {
  height: 20px !important;
  width: 20px;
}