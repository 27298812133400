.carousel {
    background: #fff;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /* height: 75px; */
}

@media screen and (max-width: 992px) {
    .carousel {
        margin-left: calc(-.9375rem);
        margin-right: calc(-.9375rem);
        width: calc(100% + (.9375rem * 2));
        border-left: none;
        border-right: none;
    }
}

.carousel .react-swipe-container {
    flex: 1;
    width: 100%;
}

.carousel-content {
    margin: 0 auto;
}

.carousel-button {
    font-family: 'Crystal-Light', sans-serif;
    margin-top: .9375rem;
    margin-bottom: .9375rem;
    padding: 0 .9375rem;
    border: none;
    text-transform: uppercase;
    color: rgba(51, 51, 51, 0.5);
    background: #fff;
    cursor: pointer;
    outline: none;
    border-right: 1px solid rgb(151, 151, 151);
    width: 100%;
    outline: none;
}

.carousel-button:first-child {
    padding-left: 0;
}

.carousel-button:last-child {
    border: none;
    padding-right: 0;
}

.carousel-button.active {
    color: #333;
}

@keyframes slideInArrow {
    0% {
        transform: translate(-50%, -100%);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

.carousel-button.active::before {
    border-width: 16px 24px 0;
}

.carousel-button.active::before {
    animation-duration: 0.25s;
    animation-name: slideInArrow;
    border-color: rgb(255, 236, 120) transparent transparent;
    border-style: solid;
    border-width: 12px 20px 0;
    content: '';
    height: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    width: 0;
}

.carousel-button h4 {
    margin: 0;
    margin-right: 4px;
}

.carousel-button p {
    font-size: 0.9rem;
    line-height: 1.1;
}

@media screen and (max-width: 992px) {
    .carousel-button h4 {
        font-size: 0.9rem;
    }

    .carousel-button p {
        font-size: 0.8rem;
    }
}

.carousel-button p:first-child {
    font-weight: 700;
}

.carousel-button p.price {
    text-transform: lowercase;
}

.carousel p {
    margin: 0;
    padding: 0;
}
