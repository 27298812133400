.dialog .dialog-buttons {
  padding: 0 .9375rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgb(238, 238, 238);
  min-height: 50px;
}

@media screen and (max-width: 992px) {
  .dialog .dialog-buttons {
    justify-content: center;
  }
}