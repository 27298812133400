@media screen and (max-width: 992px) {
  .travelers .card {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
    width: calc(100% + .9375rem * 2);
    /* border-top: none; */
    border-left: none;
    border-right: none;
  }
}

.travelers h3 {
  font-weight: bold;
}