.callout {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.callout>p {
  padding: .2rem .4687rem;
  background: #009acd;
  color: #fff;
  font-weight: 700;
  font-size: 0.8rem;
  margin: .2344rem;
}

@media screen and (max-width: 992px) {
  .callout>p:nth-child(n+3) {
    display: none;
  }
}