@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');

@font-face {
    font-family: 'Crystal-Light';
    src: url('./Crystal-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

/* Typings */

h2 {
    font-size: 2.5rem;
    margin: 0;
    margin-top: 1.5rem;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

h3 {
    font-weight: 400;
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
}

h5 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: .3rem;
}

@media screen and (max-width: 544px) {
    h2 {
        font-size: 1.875rem;
    }
}

p {
    font-size: 1rem;
    line-height: 1.5;
}

.text-right {
    text-align: right;
}

/* Extra flex stuffs */

.flex-margin {
    margin: 0 -7.5px;
}

.flex-margin>* {
    padding-left: .4687rem;
    padding-right: .4687rem;
    box-sizing: border-box;
}

.caption {
    font-size: .6875rem;
    color: #858585;
}

.subtitle {
    font-size: .6875rem;
    font-weight: 700;
}

@media screen and (min-width: 992px) {
    .caption {
        font-size: .9rem;
    }

    .subtitle {
        font-size: .9rem;
    }
}

/* Margins */

.nomargin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/* Colors */

.yellow-bg {
    background: rgb(255, 248, 202)
}

.grey-bg {
    background: rgb(245, 245, 245)
}

.red-bg {
    background: rgb(245, 0, 0);
}

.grey {
    color: #858585;
}

.warn {
    color: #cf1011;
}

.green {
    color: #47a404;
}

.white {
    color: #ffffff
}

.price {
    font-size: 1.5rem;
    font-weight: bold;
}

.old-price {
    text-decoration: line-through;
    font-size: 1.1rem;
    font-weight: bold;
}

@media only screen and (max-width: 992px) {
    .price {
        font-size: 1.3rem;
    }

    .old-price {
        text-decoration: line-through;
        font-size: 0.9rem;
    }
}

.LazyLoad {
    position: relative !important;
    float: left !important;
    transition-property: transform !important;
}

/* hide */

.hide {
    display: none;
}

.toastify-content {
    z-index: 1005 !important;
}

.hidden {
    height: 0 !important;
    width: 0 !important;
    overflow: hidden !important;
    display: inline-block !important;
    margin: 0 !important;
    padding: 0 !important;
}

.eu-directive p, .eu-directive .btn.link, .eu-directive li {
    font-size: .9rem;
}

.eu-directive ul {
    padding: 0 16px;
}

.eu-directive li {
    margin: .9375rem 0;
}

.payment-logo {
    height: 36px;
    margin-bottom: -6px;
    margin-right: 3px;
}

.payment-logo.cc {
    height: 24px;
    margin-top: 10px;
    margin-bottom: -10px;
    margin-right: 8px;
}

@media screen and (max-width: 380px) {
    .payment-logo:not(.cc), .payment-logo.cc:first-child {
        margin-left: 46px;
    }
}

/* flight time css */

.flight-time {
    border-left: 1px dotted #d4d4d4;
    position: relative;
}

.flight-time li {
    padding-top: 0;
    padding-bottom: 0;
}

.flight-time .dur {
    margin: 17px 0;
}

.flight-time .dep::before {
    content: "";
    height: 11px;
    width: 11px;
    position: absolute;
    background-color: #fff;
    border: 2px solid #d4d4d4;
    border-radius: 50%;
    left: -8px;
}