.icon-button {
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9375rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-family: 'Crystal-Light', sans-serif;
}

.icon-button:hover {
    color: #000
}