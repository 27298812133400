.hdescription h2 {
  margin: 0;
}

.hdescription.hid p {
  display: none;
}

.hdescription p:first-of-type {
  display: block;
}

@media (max-width: 991px) {
  .hdescription * {
    text-align: center;
  }
  .hdescription {
    align-items: center;
  }
  .hdescription h2 {
    display: none;
  }
}

@media (min-width: 992px) {
  .hdescription {
    align-items: flex-start;
  }
}