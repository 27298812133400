.husp {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: .9375rem;
  width: 100%;
  height: 100%;
}

.husp .usp-list-item {
  padding-left: 0;
}

@media (max-width: 992px) {
  .husp {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .husp .list {
    width: auto;
  }
  .husp h4 {
    display: none;
  }
  .husp {
    border: none;
    border-top: 1px solid #ebebeb;
    padding: 0;
    padding-top: calc(.9375rem * 2);
    margin-top: calc(.9375rem * 2);
  }
}