.cc-dialog {
    background: linear-gradient(to top, #FFEC78, #FFDC0D);
}

.card.search {
    background: linear-gradient(to top, #FFEC78, #FFDC0D);
    border: none;
    margin-top: 0;
    border-radius: 0;
    margin-bottom: 0;
    position: relative;
}

.card.search .select-wrapper:first-child {
    margin-right: .9375rem;
    margin-left: 0;
}

.card.search .select-wrapper:last-child {
    margin-left: .9375rem;
}

.card.search .selects .material-icons {
    margin-top: .9375rem;
}

.card.search .input input {
    background: rgb(255, 255, 255);
}

@media screen and (min-width: 993px) {
    .card.search .selects .material-icons {
        margin-left: .9375rem;
    }

    .card.search .select-wrapper, .card.search .input {
        margin-top: 0;
        margin-left: .9375rem;
        margin-right: .9375rem;
    }

    .card.search .input>input, .card.search .input .cc, .card.search .select-wrapper select, .cc-dialog .select-wrapper select {
        height: 30px;
        background-color: #fff;
    }

    .card.search .offer-code .input {
        margin-right: 0;
    }

    .card.search .offer-code .input>input {
        margin-bottom: -10px;
    }

    .card.search .btn {
        margin-top: 0;
        margin-bottom: 0;
    }

    .card.search .selects .material-icons {
        margin: 0;
    }

    .card.card.search .btn {
        /* height: 125px; */
        border-radius: 0;
        margin-top: -.9375rem;
        margin-bottom: -.9375rem;
        min-width: 200px;
    }
}

.card.search .select-wrapper select, .cc-dialog .select-wrapper select {
    border: none;
}

.card.search .input .cc {
    cursor: pointer;
}

.card.search .offer-code button {
    font-size: .875rem;
    font-weight: 700;
    font-family: 'Crystal-Light', sans-serif;
    display: flex;
    align-items: center;
    color: #009acd;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.card.search .travel-type {
    height: 30px;
    top: -30px;
    position: absolute;
}

.card.search .travel-type button {
    font-family: 'Crystal-Light', sans-serif;
    padding: .4rem 1rem;
    background: #FFEC78;
    font-size: .775rem;
    border: none;
    cursor: pointer;
    outline: none;
}

.card.search .travel-type button.active {
    background: #FFDC0D;
}

.card.search .travel-type button:first-child {
    border-top-left-radius: 3px;
    margin-left: .9375rem;
}

.card.search .travel-type button:last-child {
    border-top-right-radius: 3px;
}

.card.search .travel-type .material-icons {
    margin-right: 2px;
}

@media screen and (max-width: 992px) {
    .card.search .btn {
        width: 100%;
        padding: .8rem 3rem;
        font-size: 1.5rem;
    }
}

/* .card.search.last-minute {
  background: #cf1011;
}

.card.search.last-minute i {
  color: #fff;
}

.card.search.last-minute .travel-type button {
  background: #cf1011;
  color: #fff;
} */