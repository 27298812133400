.skeleton img {
    filter: blur(15px);
}

.hotel-card h4 {
    font-size: 1.1rem;
    text-align: left;
}

.hotel-card .hotel-img {
    position: relative;
}

.shadow-card {
    height: 280px;
}

.hotel-card .hotel-img>img, .hotel-card .hotel-img>.skeleton-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: rgba(133, 133, 133, .12);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

@media screen and (max-width: 992px) {
    .hotel-card {
        margin-left: calc(-.9375rem);
        margin-right: calc(-.9375rem);
        width: calc(100% + (.9375rem * 2));
        border-left: none;
        border-right: none;
        cursor: pointer;
        user-select: none;
    }
    .hotel-card * {
        user-select: none;
    }
    .shadow-card {
        height: 240px;
    }
}