.res-summary, .res-summary-details {
  padding: .9375rem 0;
  width: 100%;
}

.res-summary h4 {
  color: rgb(8, 73, 127);
  text-align: center;
  margin-bottom: .4687rem;
}

.res-summary-box {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.res-summary-box .bold {
  font-weight: bold;
}

.res-summary-list {
  background: rgb(255, 249, 203);
  box-sizing: border-box;
}

@media screen and (max-width:992px) {
  .res-summary h5m .res-summary-details h5, .res-summary p:not(.caption), .res-summary-details p:not(.caption) {
    font-size: .9rem;
  }
}

.res-summary .list p, .res-summary .list h5 {
  padding-right: .4687rem;
}