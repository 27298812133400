.card-title {
  margin: 0;
  line-height: 1.1;
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .card-title {
    font-size: 1.5rem;
  }
}