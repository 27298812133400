.dialog-container, .dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dialog-backdrop {
  z-index: 1001;
  background-color: rgba(0, 0, 0, .85);
}

.dialog-container {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  z-index: 1002;
  width: 80%;
  height: auto;
  margin: 0;
  border-radius: 0;
  max-width: 992px;
  overflow: hidden;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
}

.dialog .icon-button i {
  font-weight: bold;
}

/* .dialog .dialog-content {
  overflow-y: auto;
  height: calc(100% - 28px)
} */

@media screen and (max-width: 992px) {
  .dialog-backdrop {
    display: none;
  }
  .dialog {
    width: 100%;
    height: 100%;
  }
}