.hotel-res-summary .caption {
  margin-bottom: 4px;
}

.hotel-res-summary .caption {
  color: rgb(51, 51, 51);
  font-size: 0.9rem
}

.hotel-res-summary .btn.link {
  font-size: 0.9rem
}