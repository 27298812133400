.hfacilities {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: .9375rem;
  margin: calc(.9375rem) 0;
}

@media (max-width: 992px) {
  .hfacilities {
    border: none;
    border-top: 1px solid #ebebeb;
    padding: .9375rem 0;
  }
  .hfacilities .caption {
    font-size: 1rem;
  }
}