.bottom {
  bottom: -1px;
  background: #fff;
  padding: .4735rem;
  
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: sticky;
}

@media screen and (max-width: 992px){
  .bottom .button{
    width: 100%;
  }
}

.bottom .button {
  margin: 0 !important;
}