.checkbox input {
  display: none;
}

.checkbox label {
  font-weight: 700;
  color: rgb(0, 0, 0, 0.7);
  cursor: pointer;
  transition: color 0.25s ease;
  display: flex;
  align-items: center;
  font-size: .9375rem;
}